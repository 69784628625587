import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, Container, Grid, Stack } from "@mui/material";

import { listMetricDetails } from "../actions/metricActions";
import CompareMetrics from "../components/collaborate/CompareMetrics";
import DimensionsBreakup from "../components/collaborate/CompareMetrics/DimensionsBreakup";
import FilterStack from "../components/collaborate/Filter/FilterStack";
import FilteredInsightsGrid from "../components/collaborate/FilteredInsightsComponent/FilteredInsightsGrid";
import InsightHistogram from "../components/collaborate/InsightHistogram";
import TimePickerModal from "../components/common/TimePickerModal";
import ApiScreenError from "../components/common/error-components/ApiScreenError";
import { StyledButton } from "../components/common/mui-wrapper-components/Button";
import { Loading } from "../components/common/styled-components";
import {
  MetricDefinition,
  MetricDefinitionTypography,
  MetricDefinitionValue,
  Title,
} from "../components/common/styled-components/metric/MetricDetails.styled";
import MetricAttributesFormModal from "../components/metrics/MetricAttributesFormModal";
import MetricInfo from "../components/metrics/MetricInfo";
import MetricDetailsTab from "../components/metrics/metric-details-tab/MetricDetailsTab";
import { SET_COLLABORATE_TIMESTAMP } from "../constants/filterCriteriaConstants";
import { METRIC_UPDATE_RESET } from "../constants/metricConstants";
import { BAR_CHART, METRIC_COMPARE } from "../constants/multiSelectFilterConstants";
import { useAxios } from "../hooks/useAxios";
import useBoolean from "../hooks/useBoolean";
import { useSelectedTenant } from "../hooks/useSelectedTenant";
import { colors } from "../shared/theme-constants";
import { METRIC_L3 } from "../constants/commonConstants";

const MetricDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { metricId } = useParams();
  const globalFilter = useSelector((state)=> state.tenantGlobalFilter?.appliedFilter);
  const filterSelector = useSelector((state) => state.collaborateFilters);
  const selectedTenant = useSelectedTenant();
  const tenantConfigs = useSelector(state => state?.tenantConfigs?.configs);
  const { metric, loading, error } = useSelector(
    (state) => state.metricDetails
  );
  const  { pipelineSchedule, chartType } = useSelector((state)=> state.collaborateFilters);

  const { data: options } = useAxios("metrics_hub/metrics/options", {
    params: { tenant_id: selectedTenant },
  });
  const [
    isTimeFilterOpen,
    { setTrue: openTimeFilterModal, setFalse: closeTimeFilterModal },
  ] = useBoolean();
  const [
    isAttributesFormModalOpen,
    { setTrue: openAttributesFormModal, setFalse: closeAttributesFormModal },
  ] = useBoolean();

  function handleAttributesFormModalClose() {
    closeAttributesFormModal();
    dispatch({ type: METRIC_UPDATE_RESET });
  }

  function handleTimeSelected(selectedStartEndTimestamp) {
    dispatch({
      type: SET_COLLABORATE_TIMESTAMP,
      payload: selectedStartEndTimestamp,
    });
  }

  useEffect(() => {
    dispatch(listMetricDetails(metricId));
  }, [dispatch, metricId, globalFilter]);

  if (loading) {
    return <Loading />;
  }

  if (!metric || error) {
    return <ApiScreenError error={error} resourceType="metric" />;
  }

  return (
    <>
      {isAttributesFormModalOpen && (
        <MetricAttributesFormModal
          data={metric}
          metricId={metricId}
          options={options}
          onClose={handleAttributesFormModalClose}
        />
      )}

      {isTimeFilterOpen && (
        <TimePickerModal
          onClose={closeTimeFilterModal}
          onSelect={handleTimeSelected}
        />
      )}
      <Container id={metricId} disableGutters maxWidth={false} sx={{ py: 10, px: "10%", backgroundColor: colors.white }}>
        <Box pb={8}>
          <StyledButton onClick={() => navigate(-1)}>Back</StyledButton>
        </Box>

        <Grid container spacing={4}>
          <Grid item sm={12}>
            <Title sx={{ pb: 3 }}>{metric.kpi_display_name}</Title>
            <MetricInfo metricDetails={metric} />
            <MetricDefinition>
              <MetricDefinitionTypography>
                Definition:{" "}
              </MetricDefinitionTypography>
              <MetricDefinitionValue>
                {metric.kpi_definition ?? "NA"}
              </MetricDefinitionValue>
            </MetricDefinition>
          </Grid>
          <Grid item sm={12}>
            <FilterStack
              onTimeFilterClick={openTimeFilterModal}
              kpiId={metricId}
              tenantId={metric.tenant_id}
              pipelineSchedule={pipelineSchedule}
              chartType={chartType}
              metric={metric}
              globalFilter={globalFilter}
              pageType={METRIC_L3}
            />
          </Grid>

          {/* Compare metrics chart */}
          <Grid item sm={12}>
            <Box
              sx={{
                border: "1.39px solid #C7D1EB",
                padding: "5px 40px",
                borderRadius: 1,
              }}
            >
              {chartType === METRIC_COMPARE || chartType === BAR_CHART ? (
                <CompareMetrics
                  kpiId={metricId}
                  tenantId={metric.tenant_id}
                  metric={metric}
                  pipelineSchedule={pipelineSchedule}
                  isForecastEnabled = {tenantConfigs.is_forecast_enabled}
                />
              ) : (
                <DimensionsBreakup
                  metric={metric}
                  chartType={chartType}
                  kpiId={metricId}
                  pipelineSchedule={pipelineSchedule}
                />
              )}
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              pb={4}
            >
              <Title>Metric Details</Title>

              <Button
                data-snippyly-comment-disabled
                variant="contained"
                onClick={openAttributesFormModal}
              >
                Edit
              </Button>
            </Stack>

            <MetricDetailsTab metricDetails={metric} currencySymbol={tenantConfigs?.currency_symbol}/>
          </Grid>

          {/* Histogram */}
          { tenantConfigs?.is_forecast_enabled ? (
            <>
              <Grid item sm={12}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  width="100%"
                  pb={4}
                >
                  <Title>Insight History</Title>
                  <InsightHistogram tenantId={metric.tenant_id} pipelineSchedule={pipelineSchedule}  dimensionNames= {filterSelector?.selectedDimensionNames} dimensionValues={filterSelector?.selectedDimensionValues} />
                </Stack>
              </Grid>

              <Grid item sm={12}>
                <FilteredInsightsGrid tenantId={metric.tenant_id} pipelineSchedule={pipelineSchedule} />
              </Grid>
            </>
          ): <></> }

        </Grid>
      </Container>
    </>
  );
};

export default MetricDetailsScreen;
